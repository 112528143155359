import { config } from "@app";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import Message from "./Message";

export default function Detalles(props) {
    const { onComprar, onConsultar, puedeComprar } = props;
    return (
        <main>
            {/* Hero unit */}
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography
                                component="h2"
                                variant="h5"
                                align="center"
                                color="text.primary"
                            >
                                {config.raffle_tittle}<br/>
                                {config.raffle_tittle1}<br/>
                                
                            </Typography>
                            <Typography component="h3" variant="p" align="center" color="text.secondary" paragraph>
                                Desde ${config.packagePrices[1] || config.price} {config.currency}
                            </Typography>
                            <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            

                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <Typography variant="h9" align="center" color="text.secondary" paragraph>
                            🛑𝘾𝙤𝙣 𝙩𝙪 𝙗𝙤𝙡𝙚𝙩𝙤 𝙡𝙞𝙦𝙪𝙞𝙙𝙖𝙙𝙤 𝙥𝙖𝙧𝙩𝙞𝙘𝙞𝙥𝙖𝙨 𝙥𝙤𝙧👇<br />
                            <Typography variant="h6" align="center" color="text.primary" paragraph>
                            {config.raffle_tittle} {config.raffle_tittle1}
                             <br/>
                                
                             </Typography>
                            
                            <Typography variant="h7" align="center" color="text.primary" paragraph>
                        
                            BONO DE PRONTO PAGO 🏃‍♂️💵 
                            <br/>
                            💲200,000.00 pesos<br/> MUSTANG GT 2020 <br/>

                            <br/> 
                            👀 Mucho OJO Bonos de pronto pago Validos👀<br/>
                             Todos los bonos son válidos pidiendo y pagando dentro de la primera hora, 
                             No despreciando ningun numero, los boletos son acumulables.<br/>
                             Recuerda que se juega con TRIS CLASICO 8:00PM Hora SONORA 9:00PM Hora Centro
                            <br/>
                            BONO FORANEO 🛩️:<br/>
                            💲30,000.00 pesos (Aplica a clientes Locales y foráneos)
                             </Typography>
                            
                            <br/>
                            <br/>
                            🗓️ SORTEO 🗓️<br/>    
                            🚘{config.raffle_tittle2} {config.raffle_tittle} {config.raffle_tittle1}
                            </Typography>
                        </Grid>
                    </Grid>
                    {!puedeComprar && <Message />}
                    <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                    >
                        <Button size="large" variant='contained' color='primary' onClick={onComprar} disabled={!puedeComprar}>Comprar</Button>
                        <Button size="large" variant='outlined' onClick={onConsultar}>Consultar boleto</Button>
                    </Stack>
                </Container>
            </Box>
        </main>
    )
}