import { doc } from 'firebase/firestore'
import { firestore } from './firebase';

const allowDev = true;
const isDev = process.env.NODE_ENV === 'development';
const raffleId = 'Nu8KmtcCAEK32WdlT4lb';
const raffleRef = doc(firestore, "raffles", raffleId);
const numbersPhone = (['+526474289949','+526471182974','+526474289949','+526424827841','+526421175001','+526471275938','+526421494684','+526471581840','+526421374445']);
export const config = {
    filterLimit: undefined,
    defaultLimit: 100, //Limite por default en la seleccion de boletos
    searchLimit: 20, //Limite por default en la seleccion de boletos
    price: 35, // Precio por boleto
    packagePrices: {
        //1: 45 // 1 boleto cuesta 42
    },
    number:'47', // Numero de sorteo
    raffle_tittle: 'COMBO DIABLO',
    raffle_tittle1: 'CHEYENNE RST 2024 ACCESORIZADA ➕ MUSTANG GT 2020 ➕ 💲200,000, CON OPCION A EFECTIVO',
    raffle_tittle2: 'FECHA: 24 DE DICIEMBRE DEL 2024',
    currency: 'MXN',
    raffleId,
    rafflePad: 5,
    isDev,
    raffleRef,
    sendToNumber: () => numbersPhone[Math.floor(Math.random() * numbersPhone.length)]
}
